<script>
  import Intro from "./pages/intro.svelte";
</script>

<div class="container">
  <div class="container-logo">
    <div class="logo">N</div>
    <div class="logo">e</div>
    <div class="logo">u</div>
    <div class="logo">l</div>
    <div class="logo">u</div>
    <div class="logo">s</div>
  </div>
  <div class="container-message">Under construction.</div>
  <div class="invis">
    <a href="https://yepisyepyeniwatch.xyz/">
      https://yepisyepyeniwatch.xyz/
      <br>
      DISCLAIMER: This website nor its content bears no affiliation with me personally or professionally. I do not endorse or support any values, statements, or positions expressed herein. This clarification serves to maintain transparency regarding my non-association with this entity.
    </a>
  </div>
</div>

<style>
  .invis {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #000;
    transform: rotateX(30deg) rotateY(30deg);
    color: #fff;
    font-family: "Pretendard Variable", sans-serif;
    perspective: 15rem;
  }

  .container-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    perspective: 15rem;
  }

  .container-message {
    font-size: 1.5rem;
    font-weight: 100;
    animation: moving 5s ease-in-out infinite;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 5rem;
    animation: moving 1s ease-in-out infinite;
    text-shadow: 0 0 0.5rem #fff;
  }

  .logo:nth-child(1) {
    animation-delay: 0s;
  }

  .logo:nth-child(2) {
    animation-delay: 0.1s;
  }

  .logo:nth-child(3) {
    animation-delay: 0.2s;
  }

  .logo:nth-child(4) {
    animation-delay: 0.3s;
  }

  .logo:nth-child(5) {
    animation-delay: 0.4s;
  }

  .logo:nth-child(6) {
    animation-delay: 0.5s;
  }

  :global(body) {
    padding: 0;
    margin: 0;
    background-color: #000;
  }

  @keyframes moving {
    0% {
      transform: translateZ(0);
      text-shadow: 0 0 0.1rem #fff;
    } 
    50% {
      transform: translateZ(2rem);
      text-shadow: 0 0 0.1rem #fff, 0 0 0.2rem #fff;
    }
    100% {
      transform: translateZ(0);
      text-shadow: 0 0 0.1rem #fff;
    }
  }

  @media (min-width: 768px) {
    .container-message {
      font-size: 3rem;
    }

    .logo {
      font-size: 10rem;
    }
  }
</style>
